@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient-a {
  background: linear-gradient(
    to right,
    rgba(232, 199, 230, 1) 0%,
    rgba(255, 201, 191, 1) 100%
  );
}
.gradient-a-border {
  border-width: 20px 0 0 0;
  border-image: linear-gradient(
      to right,
      rgba(233, 200, 230, 1),
      rgba(255, 202, 192, 1)
    )
    1;
  border-image-slice: 1;
}
.gradient-b {
  background: linear-gradient(
    to right,
    rgba(255, 210, 205, 1),
    rgba(253, 216, 187, 1),
    rgba(255, 226, 231, 1),
    rgba(232, 203, 233, 1)
  );
}
.gradient-gray {
  background: linear-gradient(to right, #25293e 0%, #49475f 100%);
}
.drop-shadow-a {
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}
.gradient-c {
  background: linear-gradient(
    to right,
    rgba(255, 161, 162, 1) 0%,
    rgba(163, 71, 255, 1) 100%
  );
}
.gradient-d {
  background: linear-gradient(
    to right,
    rgba(252, 158, 167, 1) 0%,
    rgba(168, 76, 251, 1) 100%
  );
}
.linear-gradient-background {
  background: linear-gradient(
    180deg,
    rgba(243, 200, 210, 1) 16%,
    rgba(254, 220, 207, 1) 100%
  );
}
.box-shadow-one {
  box-shadow: 1px 3px 4px 0 rgba(0, 0, 0, 0.25);
}
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(
    to right,
    rgba(255, 161, 162, 1) 0%,
    rgba(163, 71, 255, 1) 100%
  );
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    to right,
    rgba(255, 161, 162, 1) 0%,
    rgba(163, 71, 255, 1) 100%
  );
}
.custom-box-shadow {
  box-shadow: rgba(249, 240, 251, 1) 0px -4px 4px 0px,
  rgba(239, 237, 251, 1) 0px -4px 10px 0px;
}
